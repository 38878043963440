<template>
  <div
    class="flex flex-col flex-1 bg-cover bg-no-repeat bg-top"
    :style="{
    backgroundImage: `url(${backgroundImg})`
  }"
  >
    <div class="container my-auto">
      <h1 class="text-center my-5">Chọn Nơi Khám Bệnh</h1>
      <b-row
        class="justify-content-center"
        v-if="defaultWs.ws_public && defaultWs.ws_public.workspaces"
      >
        <b-col
          :md="6"
          :xl="defaultWs.ws_public.workspaces.length < 6 ? 4 : 4"
          class="mb-4 mb-md-5"
          v-for="(ws, index) in defaultWs.ws_public.workspaces"
          :key="index"
        >
          <b-card no-body class="h-full shadow group transform xl:hover:scale-105 duration-200">
            <template #header>
              <h4 class="text-sm mb-0">{{ getOrgInfo(ws.org_id) }}</h4>
            </template>
            <b-row class="gx-3 flex-1">
              <b-col md="3">
                <div class="h-full">
                  <b-card-img
                    :src="getImageUrl(ws.avatar)"
                    :alt="ws.name"
                    class="rounded-0 h-full object-cover px-24 md:px-0"
                    @error="(e) => appUtils.onHandleErrorLoadImage(e)"
                  ></b-card-img>
                </div>
              </b-col>
              <b-col md="9">
                <b-card-body class="flex flex-col ps-md-0 pe-md-2 py-2 h-full">
                  <b-card-title
                    class="text-lg mb-1 txt-pri-900 group-hover:text-blue-600 transition-all duration-200"
                  >{{ ws.name }}</b-card-title>
                  <b-card-text v-if="ws.address && ws.address !== 'null'">
                    <i class="el-icon-location txt-pri-900" />
                    <span class="ml-1">{{ ws.address }}</span>
                  </b-card-text>
                  <b-card-text class="mt-auto">
                    <button
                      class="btn bg-pri text-white w-full"
                      type="button"
                      @click="handleSelectedWorkspace(ws)"
                    >Chọn nơi khám</button>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <div v-else-if="!defaultWs.name">
        <b-alert show variant="danger" class="text-center">
          <span>Không tìm thấy thông tin nơi khám</span>
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import backgroundImg from '../../assets/bg1.jpg'
import { mapGetters } from 'vuex'
import appUtils from '../../utils/appUtils'

export default {
  name: 'MedicalRegister',
  data () {
    return {
      backgroundImg,
      appUtils
    }
  },
  computed: {
    ...mapGetters('registerMedical', ['defaultWs'])
  },
  methods: {
    getOrgInfo (org_id) {
      const subOrgsRaw = this.defaultWs?.ws_public?.sub_org || []
      const orgRaw = {
        id: this.defaultWs?.ws_public?.org_id,
        name: this.defaultWs?.ws_public?.name,
        address: this.defaultWs?.ws_public?.address
      }
      const subOrgs = [...[orgRaw], ...subOrgsRaw]
      const data = subOrgs?.find(item => item?.id === org_id)
      return data?.name || ''
    },
    getImageUrl (path) {
      return appUtils.getDocumentURL(path)
    },
    handleSelectedWorkspace (data) {
      const { id } = data
      this.$store.commit('registerMedical/setSelectedWs', data)
      this.$router
        .push({
          name: 'ExaminationRegisterForm',
          params: {
            ws_id: id
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss">
</style>
